import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import Button from '@components/common/Button';
import { countryCodeSpanishLabelMapper } from '@components/common/country/countries';
import CountryAutocomplete from '@components/common/country/CountryAutocomplete';
import { CountryCode } from '@components/common/country/types';
import { Size } from '@components/common/size';
import { CalendarInputType } from '@components/datePick/aeht-chakra-dayzed-datepicker/src/utils/commonTypes';
import DatePickRangeModal from '@components/datePick/DatePickRangeModal';
import Image from '@components/images/RentennialsNextImage';
import { AVAILABLE_COUNTRIES } from '@constants/index';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_SEARCH_VALUES, generateFixTimeSlot, getHalfHours, getNextTime, isTodayOrTomorrow } from '@utils/dates';
import { convertToDateTime } from '@utils/dates';
import { useEvent } from '@utils/hooks/useEvent';
import { tw } from '@utils/tailwind-utils';
import { useTranslation } from 'next-i18next';
import { City } from 'src/api/types/cities';

import FromIcon from '../../../public/images/Icon_Desde.svg';
import ToIcon from '../../../public/images/Icon_Hasta.svg';
import { SearchParams, SearchParamsType } from '../../api/types/searchParamsSchema';

import CityPick from './CityPick';
import TimePick from './TimePick';
import { SearchBarFormSchema, SearchBarFormType } from './types';

export interface SearchBarProps {
  cities: City[];
  className?: string;
  size?: Size;
  currentCity?: string;
  currentCountry?: string;
  currentDefaultSearchValues?: Omit<SearchParamsType, 'city' | 'country'>;
  onSearchBarSubmit: (searchParams: SearchParamsType) => Promise<void>;
  searchLabel?: string;
  buttonClassName?: string;
  noDates: boolean;
  setNoDates: (hide: boolean) => void;
  cityPickSX?: SystemStyleObject;
  isModal?: boolean;
}

const SearchBar: FC<SearchBarProps> = ({
  cities,
  className,
  currentCity,
  currentCountry,
  size = 'md',
  currentDefaultSearchValues,
  onSearchBarSubmit,
  searchLabel,
  buttonClassName,
  noDates,
  setNoDates,
  cityPickSX,
  isModal
}) => {
  const { t } = useTranslation(['search']);
  const [isRangePickerModalOpen, setIsRangePickerModalOpen] = useState<boolean>(false);
  const [typeInputSelect, setTypeInputSelect] = useState<CalendarInputType>();
  const [isCitiesResetting, setIsCitiesResetting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fixedDefaultSearchValues = (
    currentDefaultSearchValues?.fromDate ? currentDefaultSearchValues : DEFAULT_SEARCH_VALUES
  ) as Omit<SearchParams, 'city'>;

  fixedDefaultSearchValues.toDate;
  const [dateRange, setDateRange] = useState<{ toDate: string; fromDate: string }>({
    fromDate: (fixedDefaultSearchValues.fromDate
      ? convertToDateTime(fixedDefaultSearchValues.fromDate)
      : convertToDateTime(DEFAULT_SEARCH_VALUES.fromDate)
    )
      .startOf('day')
      .toISO()!,
    toDate: convertToDateTime(fixedDefaultSearchValues.toDate).startOf('day').toISO()!
  });
  const searchBarFormMethods = useForm<SearchBarFormType>({
    resolver: zodResolver(SearchBarFormSchema),
    defaultValues: {
      city: currentCity,
      country:
        (currentCountry && Object.keys(CountryCode).includes(currentCountry) && (currentCountry as CountryCode)) ||
        undefined,
      dates: {
        dateFrom: fixedDefaultSearchValues.fromDate,
        dateTo: fixedDefaultSearchValues.toDate
      },
      fromDateTime: generateFixTimeSlot(fixedDefaultSearchValues.fromDate, fixedDefaultSearchValues.fromDateTime),
      toDateTime: fixedDefaultSearchValues.toDateTime
    },
    mode: 'onChange'
  });

  const { control, handleSubmit, setValue, watch } = searchBarFormMethods;

  const searchSubmit = useEvent(async ({ dates, ...rest }: SearchBarFormType) => {
    try {
      setIsSubmitting(true);
      await onSearchBarSubmit({
        fromDate: dates.dateFrom,
        toDate: dates.dateTo,
        ...rest
      });
    } finally {
      setIsSubmitting(false);
    }
  });

  const countryW = watch('country');
  const cityW = watch('city');

  const filteredCities = useMemo(() => {
    return cities.filter(({ country }) => countryW && countryCodeSpanishLabelMapper[countryW] === country);
  }, [cities, countryW]);

  useEffect(() => {
    if (filteredCities.every(({ id }) => id !== cityW)) {
      setValue('city', '');
      setIsCitiesResetting(true);
    }
  }, [setValue, filteredCities, cityW]);

  useEffect(() => {
    const subscription = watch(({ dates, fromDateTime }, { name }) => {
      if (name === 'dates' && dates?.dateFrom && dates?.dateTo) {
        const { dateFrom, dateTo } = dates;
        setDateRange({
          fromDate: dateFrom,
          toDate: dateTo
        });
        setValue('fromDateTime', getNextTime(dateFrom, fromDateTime));
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  const currentIsTodayOrTomorrow = isTodayOrTomorrow(dateRange.fromDate);

  const handleClickFromDate = () => {
    setIsRangePickerModalOpen(true);
    setTypeInputSelect('fromDate');
  };

  const handleClickToDate = () => {
    setIsRangePickerModalOpen(true);
    setTypeInputSelect('toDate');
  };

  return (
    <FormProvider {...searchBarFormMethods}>
      <form onSubmit={handleSubmit(searchSubmit)} className={tw('flex  justify-center', className)}>
        <div className="flex-1">
          <div className="gap-x-w flex items-center pl-2">
            <MapPinIcon
              className={tw('h-8 w-7 text-rentennials-primary', {
                'h-6 w-5': size === 'sm'
              })}
            />
            <span
              className={tw('pl-1 font-bree-serif text-[17px] leading-[18px] text-rentennials-primary', {
                'text-[12px] leading-3': size === 'sm'
              })}
            >
              {t('origin')}
            </span>
          </div>
          <div className="flex flex-col gap-x-0.5 gap-y-4 min-[601px]:flex-row">
            <div className="mt-[6px] w-full rounded-[40px]">
              <Controller
                control={control}
                name="country"
                render={({ field: { value, onChange, ref } }) => (
                  <CountryAutocomplete
                    fixedCountries={AVAILABLE_COUNTRIES}
                    onChange={onChange}
                    initialValue={currentCountry}
                    ref={ref}
                    size={size}
                    selectedValue={value}
                    inputSX={{
                      ...cityPickSX,
                      '@media screen and (min-width: 601px)': {
                        borderRightRadius: 0
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="mt-[6px] w-full rounded-[40px]">
              <CityPick
                cities={filteredCities}
                size={size}
                isCityResetting={isCitiesResetting}
                onResetCitiesCompleted={() => setIsCitiesResetting(false)}
                currentCity={currentCity}
                inputSX={{
                  ...cityPickSX,
                  '@media screen and (min-width: 601px)': {
                    borderLeftRadius: 0
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={tw(
            'flex w-full flex-1 flex-row justify-between gap-x-4 gap-y-6  max-[600px]:flex-col tablet:justify-evenly',
            {
              'flex-col': isModal
            }
          )}
        >
          <div className="tablet:flex-1">
            <div className="flex items-center gap-x-2 pl-2">
              <Image
                src={FromIcon}
                alt="From icon"
                height={22}
                width={18}
                className={tw('h-8 w-7', {
                  'h-6 w-5': size === 'sm'
                })}
              />
              <span
                className={tw('font-bree-serif text-[17px] leading-[18px] text-rentennials-primary', {
                  'text-[12px] leading-3': size === 'sm'
                })}
              >
                {t('since')}
              </span>
            </div>
            <div className="mt-[6px] flex w-full gap-x-0.5">
              <Button
                type="button"
                onClick={handleClickFromDate}
                className="flex-1 rounded-l-full bg-rentennials-inputBg text-left font-roboto text-sm font-normal text-rentennials-input hover:bg-rentennials-inputBg/75 hover:opacity-70 lg:justify-center lg:px-0"
              >
                <div className="flex w-full items-center justify-between px-2 ">
                  <span
                    className={tw('min-w-[91px] px-4 text-center', {
                      'text-xs': size === 'sm'
                    })}
                  >
                    {noDates ? '-' : convertToDateTime(dateRange.fromDate).toFormat('dd/MM/yy')}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 stroke-2 text-rentennials-input" />
                </div>
              </Button>
              <TimePick
                controlName="fromDateTime"
                size={size}
                className="flex-1"
                noDates={noDates}
                hours={getHalfHours(currentIsTodayOrTomorrow)}
              />
            </div>
          </div>
          <div className="tablet:flex-1">
            <div className="flex items-center gap-x-2 pl-2">
              <Image
                src={ToIcon}
                alt="To Icon"
                height={22}
                width={18}
                className={tw('h-8 w-7', {
                  'h-6 w-5': size === 'sm'
                })}
              />
              <span
                className={tw('font-bree-serif text-[17px] leading-[18px] text-rentennials-primary', {
                  'text-[12px] leading-3': size === 'sm'
                })}
              >
                {t('to')}
              </span>
            </div>
            <div className="mt-[6px] flex w-full gap-x-0.5">
              <Button
                type="button"
                onClick={handleClickToDate}
                className="flex-1 rounded-l-full bg-rentennials-inputBg text-left font-roboto text-sm font-normal text-rentennials-input hover:bg-rentennials-inputBg/75 hover:opacity-80 lg:justify-center lg:px-0"
              >
                <div className="flex w-full items-center justify-between px-2">
                  <span
                    className={tw('min-w-[91px] px-4 text-center', {
                      'text-xs': size === 'sm'
                    })}
                  >
                    {noDates ? '-' : convertToDateTime(dateRange.toDate).toFormat('dd/MM/yy')}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 stroke-2 text-rentennials-input" />
                </div>
              </Button>
              <TimePick controlName="toDateTime" size={size} className="flex-1" noDates={noDates} />
            </div>
          </div>
        </div>
        <div
          className={tw('flex items-end pt-4 min-[850px]:pt-0', {
            'min-[850px]:pt-4': isModal
          })}
        >
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={cities.length === 0}
            className={tw(
              'w-full rounded-[20px] bg-rentennials-primary px-4',
              {
                'h-6': size === 'sm',
                'h-8': size === 'md'
              },
              buttonClassName
            )}
          >
            <div
              className={tw('flex items-center justify-center gap-x-2 min-[950px]:flex ', {
                'min-[850px]:flex': isModal
              })}
            >
              <MagnifyingGlassIcon className="h-4 w-4 text-white" />
              <span
                className={tw(
                  'inline h-full font-bree-serif text-lg font-normal leading-[18px] tracking-wider text-white min-[950px]:inline',
                  {
                    'pb-[2px] text-[12px]': size === 'sm',
                    'min-[850px]:inline': isModal
                  }
                )}
              >
                {searchLabel ? searchLabel : t('search')}
              </span>
            </div>
          </Button>
        </div>
        {isRangePickerModalOpen && (
          <Controller
            name="dates"
            control={control}
            render={({
              field: {
                onChange,
                value: { dateFrom, dateTo }
              }
            }) => (
              <DatePickRangeModal
                timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                onClose={() => setIsRangePickerModalOpen(false)}
                dateFrom={dateFrom}
                dateTo={dateTo}
                noDates={noDates}
                minDateDifference={2}
                onDateChange={(dateFrom, dateTo) => {
                  setNoDates(false);
                  onChange({ dateFrom, dateTo });
                }}
                typeInputSelect={typeInputSelect}
              />
            )}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default SearchBar;
